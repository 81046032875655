var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    attrs: {
      "placeholder": "Search category",
      "label": "Search category"
    },
    on: {
      "input": _vm.searchCategory
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }