<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <v-select
          v-if="!selectedData"
          v-model="form.alternative.data_type"
          :items="typeOptions"
          item-text="name"
          item-value="value"
          label="Select Alternative Type"
          class="mt-5"
          outlined
          :rules="[required]"
        ></v-select>

        <div
          v-if="form.alternative.data_type === 'category'"
          class="d-flex"
        >
          <template v-if="!selectedData">
            <SearchCategory
              class="mr-2"
              @records="records => dataOptions = records.map(cat => ({ name: cat.key, value: cat }))"
            />

            <v-select
              v-model="selectedData"
              :items="dataOptions"
              item-text="name"
              item-value="value"
              label="Category"
              outlined
              :rules="[required]"
              @input="updatedDataOption"
            ></v-select>
          </template>

          <div
            v-else
            class="mb-5 mt-5"
          >
            <p>Adding alternative replacement for <b class="ml-2">{{ selectedData.name.en }}</b></p>

            <!-- <p>Randomly assigned dict key: <b>{{ form.dictionary.key }}</b></p> -->
          </div>
        </div>

        <template v-if="selectedData">
          <v-text-field
            v-model="form.dictionary.key"
            label="Unique Dictionary Key"
            class="mt-5"
            outlined
            :rules="[required]"
          />

          <v-autocomplete
            v-model="form.dictionary.distributor_id"
            :items="distributorOptions"
            item-text="title"
            item-value="value"
            label="Select Distributor to see it"
            outlined
            :rules="[required]"
          ></v-autocomplete>

          <translatable-input
            v-model="form.dictionary.value"
            label="Replace Value"
            allow-empty
          />

          <v-text-field
            v-model="form.dictionary.notes"
            label="Notes"
            hint="optional."
            outlined
          />
        </template>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="!form.dictionary.distributor_id"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useDistributor, useTranslatable } from '@/composables'
import SearchCategory from '@/modules/product/components/SearchCategory.vue'
import { required } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    TranslatableInput,
    SearchCategory,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },

  setup(props, { emit }) {
    const { distributorOptions } = useDistributor()

    const dataOptions = ref([])

    const form = ref({
      dictionary: {
        key: '',
        category: 'alternative',
        value: useTranslatable(''),
        notes: '',
        distributor_id: null,
      },
      alternative: {
        data_type: 'category',
        data_key_value: '',
        data_key_field: '',
        translatable_field: '',
        dictionary_id: null, // updated by parent, not here.
      },
    })
    const formElem = ref(null)

    const selectedData = ref(null)

    const updatedDataOption = opt => {
      const { alternative } = form.value
      const { data_type } = alternative
      let fieldValue = null

      console.log('selected cat', opt)
      let dataKeyField = 'uuid'
      const translatableField = 'name'

      switch (data_type) {
        case 'category':
          dataKeyField = 'key'
          break

        default:
          break
      }

      alternative.data_key_value = opt[dataKeyField]
      alternative.data_key_field = dataKeyField
      alternative.translatable_field = translatableField
      fieldValue = opt[translatableField]

      form.value.dictionary.value = fieldValue

      if (!form.value.key) {
        form.value.dictionary.key = uuidv4()
      }

      form.value.alternative = alternative
    }

    const typeOptions = ref([
      {
        name: 'Category',
        value: 'category',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      dataOptions,
      form,
      formElem,
      validate,
      required,
      distributorOptions,
      typeOptions,

      selectedData,
      updatedDataOption,
    }
  },
}
</script>
