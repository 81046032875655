<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 480 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <create-alternative-form
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    >
    </create-alternative-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeAlternative, storeDictionary } from '@api/site/site'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import CreateAlternativeForm from './CreateAlternativeForm.vue'

export default {
  components: {
    CreateAlternativeForm,
  },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const { data } = await storeDictionary(form.dictionary).catch(err => {
        errors.value = useExtractErrors(err)
        loading.value = false
      })

      const { data: dictionary } = data
      await storeAlternative({
        ...form.alternative,
        dictionary_id: dictionary.id,
      }).then(async res => {
        emit('changed', res.data.data)
      })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })

      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
