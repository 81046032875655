<template>
  <v-text-field
    v-model="searchInput"
    placeholder="Search category"
    label="Search category"
    @input="searchCategory"
  >
  </v-text-field>
</template>

<script>
import { fetchCategories } from '@/api/product/product'
import { ref } from '@vue/composition-api'
import { debounce } from 'lodash'

export default {
  props: {
    take: {
      type: Number,
      default: 100,
    },
    query: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const searchInput = ref(props.query)

    const searchCategory = debounce(async () => {
      const { data } = await fetchCategories({ take: props.take, search: searchInput.value })

      const { records } = data.data

      emit('records', records)

      emit(
        'options',
        records.map(u => ({ title: u.name.en, value: u.id })),
      )
    }, 300)

    if (props.query) searchCategory()

    return {
      searchInput,
      searchCategory,
    }
  },
}
</script>
