<template>
  <div id="site-list">
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <div class="d-flex align-center">
          <distributor-id-filter
            v-model="filters"
            chips
            clearable
            style="min-width: 200px;"
          />

          <!-- <v-select
            v-model="filters.data_type"
            class="mr-2"
            placeholder="Data Type"
            :items="dataTypeOptions"
            item-text="title"
            item-value="value"
            style="min-width: 100px;"
            clearable
          ></v-select> -->

          <v-btn
            color="primary"
            block
            class=""
            :loading="loading"
            :disabled="loading"
            @click="loadData"
          >
            <v-icon class="prefix-icon">
              {{ icons.mdiRefresh }}
            </v-icon>
            <span>
              {{ $t('common.list.refresh') }}
            </span>
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          #{{ item.id }}
        </template>

        <!-- name -->
        <template #[`item.name`]="{ item }">
          <span class="text-no-wrap">{{ t(item.dictionary.name) }}</span>
        </template>

        <!-- value -->
        <template #[`item.value`]="{ item }">
          <div class="text-wrap dict-value">
            <b>{{ t(item.dictionary.value) }}</b>
            <v-icon
              link
              @click="setAside(item.dictionary, 'dictionary')"
            >
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </template>

        <!-- distributor -->
        <template #[`item.distributor`]="{ item }">
          <span class="text-wrap">{{ item.dictionary.distributor?.business_name }}</span>
        </template>

        <!-- notes -->
        <template #[`item.notes`]="{ item }">
          <span class="text-wrap">{{ item.dictionary.notes }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <!-- <v-list-item
                  link
                  @click="setAside(item.dictionary, 'dictionary')"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit translation</span>
                  </v-list-item-title>
                </v-list-item> -->
                <v-list-item
                  link
                  @click="deleteAlternative(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <create-alternative-aside
      v-if="aside === 'create_alternative'"
      v-model="aside"
      @changed="loadData(); aside = false"
    />

    <dictionary-aside
      v-if="aside === 'dictionary'"
      v-model="aside"
      :resource="resource"
      @changed="loadData(); aside = false"
    />
  </div>
</template>

<script>
import {
  mdiDeleteOutline, mdiDotsVertical,
  mdiMenuDown,
  mdiMenuUp,
  mdiPencilOutline,
  mdiPlus,
  mdiRefresh,
} from '@mdi/js'

import { DistributorIdFilter } from '@/components'
import { useDistributor } from '@/composables'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import CreateAlternativeAside from './dictionary-resource/CreateAlternativeAside.vue'
import DictionaryAside from './dictionary-resource/DictionaryAside.vue'
import useAlternatives from './useAlternatives'

export default {
  components: {
    CreateAlternativeAside,
    DictionaryAside,
    DistributorIdFilter,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteAlternative,
    } = useAlternatives()

    const actions = ['Edit']

    const aside = ref('')
    const resource = ref(null)

    const setAside = (item, type = 'create_alternative') => {
      aside.value = type
      resource.value = item
    }

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    const { distributorOptions } = useDistributor()

    const dataTypeOptions = [
      { title: 'Category', value: 'category' },
    ]

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      loadData,
      deleteAlternative,
      singleSelect,
      headerprops,
      showFilter,
      distributorOptions,
      dataTypeOptions,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .dict-value {
    max-height: 45px;
    overflow: hidden;
  }
  </style>
@/modules/distributor/views/useDistributor
